<template>
  <div>
    <navigation :left="left" :msg="msg"></navigation>
    <van-tabs @click="clicktab" v-model="active">
      <van-tab title="邀请注册记录">
        <div class="inviteimg">
          您已邀请{{ theall1.data.count }}个注册，其中{{
            theall1.data.count1 > 0 ? theall1.data.count1 : "0"
          }}个已经成功充值
        </div>
        <div
          v-for="(item, index) in allmsg1"
          v-bind:key="index"
          class="themsg themsg2"
        >
          <div class="theleftmsg">
            <!-- <div class="theimg"><img src="../../assets/userss.png" /></div> -->
            <div class="thepclass">
              <div class="themsgclass1">
                <div>{{ item.company_name?item.company_name:"该企业未认证" }}</div>

                <img v-show="item.company_name!=''&&item.company_name!=undefined" class="cerimg" src="../../assets/certification.png" />
              </div>
              <div class="timemsgclass">
                <div class="accclass">{{ item.account }}</div>
                <div class="timeclass">
                  <img id="timeimg" src="../../assets/time.png" />
                  <div>{{ item.add_time}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div>
            <div class="thenomsg"></div>
            <div class="timeclass">
              <img id="timeimg" src="../../assets/time.png" />
              <div>{{item.add_time}}</div>
            </div>
          </div> -->
        </div>
      </van-tab>
      <van-tab title="邀请收益记录">
        <div class="inviteimg">
          邀请企业已为您贡献{{ allAmount?allAmount.toLocaleString():"0.00" }}收益
        </div>
        <div v-for="(item, index) in allmsg2" v-bind:key="index" class="themsg">
          <div class="nati">
            <div class="thenameclass">{{ item.company_name==""&&item.company_name==undefined?"该企业未认证":item.company_name }}</div>
            <div>
              <div class="timeclass thim">
                <img id="timeimg" src="../../assets/time.png" />
                <div>{{ item.add_time}}</div>
              </div>
            </div>
          </div>
          <div class="money">收益{{ item.channel_money }}元</div>
        </div>
      </van-tab>
    </van-tabs>
     <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="kong"></div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
import loading from "../../components/loading/index";
export default {
  components: {
    navigation,
    loading,
  },
  data() {
    return {
      msg: "邀请记录",
      left: "false",
      active: 0,
      tabindex: 0, //tab下标
      allmsg1: "", //注册记录
      allmsg2: "", //充值记录
      pageno1: 0,
      pageno2: 0,
      people: 0,
      income: 10000,
      allAmount:"", //邀请收益
      theall1: {
        data: {
          count: "",
        },
      },
      theall2: {
        data: {
          income_money: "",
        },
      },
    };
  },
  methods: {
    clicktab(msg) {
       
      this.$refs.loades.empty();
      this.tabindex = msg;
      if (this.tabindex == 0) {
        // this.$router.go(0);
        this.getallmsg1();
      } else if (this.tabindex == 1) {
        this.getallmsg2();
      }
    },

    //获取邀请充值记录
    getallmsg2() {
      this.$refs.loades
        .loadhttpes("/firm/v1/Channel/my_profit1", {
          reqType: "userinfo",
          pageno: "1",
          limit: "15",
          date: new Date().getFullYear()+"-"+((new Date().getMonth()+"").length==1?"0"+new Date().getMonth():new Date().getMonth()),
        })
        .then((res) => {
           console.log(res);
           this.allAmount = res.res.data.allAmount; //邀请收益
          this.allmsg2 = res.arraymsg;
          this.theall2 = res.res;
        });
    },

    //获取邀请注册记录
    getallmsg1() {
      this.$refs.loades
        .loadhttpes("/firm/v1/Channel/apply_list", {
          reqType: "userinfo",
          pageno: "1",
          limit: "15",
        })
        .then((res) => {
           
          this.allmsg1 = res.arraymsg;
          this.theall1 = res.res;
        });
    },

    loaddom() {
      if (this.tabindex == "1") {
        
        this.$refs.loades
          .loadhttpes("/firm/v1/Channel/my_profit1", {
            reqType: "userinfo",
            pageno: ++this.pageno2,
            limit: "15",
            date: new Date().getFullYear()+"-"+new Date().getMonth(),
          })
          .then((res) => {
             
            this.allmsg2 = res.arraymsg;
            this.theall2 = res.res;
          });
      } else if (this.tabindex == "0") {

        this.$refs.loades
          .loadhttpes("/firm/v1/Channel/apply_list", {
            reqType: "userinfo",
            pageno: ++this.pageno1,
            limit: "15",
          })
          .then((res) => {
             
            this.allmsg1 = res.arraymsg;
            this.theall1 = res.res;
             
          });
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.timemsgclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.18rem;
}
.accclass {
  font-size: 0.24rem;
  line-height: 0.3rem;
}
.themsgclass1 {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  line-height: 0.34rem;
}
.cerimg {
  width: 0.27rem;
  height: 0.32rem;
  margin-left: 0.14rem;
}
.thenomsg {
  height: 0.5rem;
}
.thenameclass {
  font-size: 0.25rem;
}
.kong {
  height: 1.8rem;
}
.money {
  font-size: 0.3rem;
  color: #f55613;
  margin-top: 0.28rem;
}
.nati {
  display: flex;
  font-size: 0.26rem;
  color: #141f33;
  align-items: center;
  justify-content: space-between;
}
.timeclass {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
}
.thim {
  padding-top: 0;
}
#timeimg {
  width: 0.21rem;
  height: 0.21rem;
  margin-right: 0.1rem;
}

.theleftmsg {
  display: flex;
  align-items: center;
  width: 100%;
}
.themsg2 {
  display: flex;
  justify-content: space-between;
}
.themsg {
  margin: 0.2rem 0.31rem;
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding: 0.35rem;
}
.thepclass{
  padding-top: .1rem;
  min-width: 100%;
}
.thepclass p:first-child {
  font-size: 0.25rem;
  margin: 0.09rem 0.1rem;
}
.thepclass p:nth-child(2) {
  font-size: 0.24rem;
  margin: 0.09rem 0.1rem;
}
.theimg{
  display: flex;
  align-items: center;
}
.theimg img {
  width: 0.95rem;
  height: 0.95rem;
}
.inviteimg {
  height: 1.36rem;
  background-image: url("../../assets/inviteimg.png");
  background-size: 100% 100%;
  text-align: center;
  line-height: 1.36rem;
  font-size: 0.32rem;
  font-weight: bold;
}
</style>